/* eslint-disable */

<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card class="mb-base">
        <div class="">
          <div class="simulation-10">
            <div class="vx-row my-4">
              <div class="vx-col w-3/12 center-2"></div>
              <div class="vx-col w-3/12 center-2"></div>
              <div class="vx-col w-6/12 center-2">
                <div class="tenth-block">
                  <div class="tenth-image-block">
                    <img
                      draggable="false"
                      alt=""
                      class="tenth-image"
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/333249633964785664/database.png"
                      width="60"
                    />
                  </div>
                  <h1 class="tenth-block-text-small">DNS Server<br />&zwj;10.10.92.65</h1>
                </div>
                <div class="fw-line-2-656"></div>
              </div>
            </div>
            <div class="vx-row my-4">
              <div class="vx-col w-3/12 center-2">
                <div
                  class="tenth-internet-block-2 cursor-pointer"
                  @click="wap_show_config = !wap_show_config"
                  :class="wap_show_config ? 'bg-danger' : ''"
                >
                  <div class="tenth-image-block">
                    <img
                      draggable="false"
                      alt=""
                      class="tenth-image"
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/333249633964785664/wifi-router.png"
                      width="60"
                    />
                  </div>
                  <h1 class="tenth-internet-text">WAP</h1>
                </div>
              </div>
              <div class="vx-col w-3/12 center-2">
                <div class="tenth-firewall-block-2">
                  <div class="tenth-image-block">
                    <img
                      draggable="false"
                      alt=""
                      class="tenth-image"
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/333249633964785664/server-control.png"
                      width="60"
                    />
                  </div>
                  <h1 class="tenth-internet-text">Switch</h1>
                </div>
                <div class="fw-line-1-656"></div>
                <div class="line-internet-up"></div>
              </div>
              <div class="vx-col w-6/12 center-2">
                <div
                  class="tenth-block cursor-pointer"
                  @click="aaa_server_show = !aaa_server_show"
                  :class="aaa_server_show ? 'bg-danger' : ''"
                >
                  <div class="tenth-image-block">
                    <img
                      draggable="false"
                      alt=""
                      class="tenth-image"
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/333249633964785664/database.png"
                      width="60"
                    />
                  </div>
                  <h1 class="tenth-block-text-small">AAA Server<br />10.20.21.22</h1>
                </div>
                <div class="fw-line-2-656"></div>
              </div>
            </div>
            <div class="vx-row my-4">
              <div class="vx-col w-3/12 center-2"></div>
              <div class="vx-col w-3/12 center-2"></div>
              <div class="vx-col w-6/12 center-2">
                <div class="tenth-block">
                  <div class="tenth-image-block">
                    <img
                      draggable="false"
                      alt=""
                      class="tenth-image"
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/333249633964785664/database.png"
                      width="60"
                    />
                  </div>
                  <h1 class="tenth-block-text-small">SNMP Server<br />10.20.1.23</h1>
                </div>
                <div class="fw-line-2-656"></div>
              </div>
            </div>
            <div class="vx-row my-4">
              <div class="vx-col w-3/12 center-2"></div>
              <div class="vx-col w-3/12 center-2"></div>
              <div class="vx-col w-6/12 center-2">
                <div class="tenth-block">
                  <div class="tenth-image-block">
                    <img
                      draggable="false"
                      alt=""
                      class="tenth-image"
                      src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/333249633964785664/database.png"
                      width="60"
                    />
                  </div>
                  <h1 class="tenth-block-text-small">AD Server<br />&zwj;10.31.22.10</h1>
                </div>
                <div class="fw-line-2-656"></div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>

      <slide-x-left-transition>
        <div v-if="aaa_server_show" style="position: absolute; top: 38%; right: 25vw; min-width: 13.5vw; z-index: 100">
          <vx-card class="pt-0" icon="move" style="background-color: #181d2a; height: 100%" title="AAA Server">
            <template slot="actions">
              <div class="flex items-center align-middle text-danger" @click="aaa_server_show = false">
                <feather-icon icon="XCircleIcon" svgClasses="w-8 h-8" />
              </div>
            </template>
            <table class="w-full mt-4">
              <tr>
                <td class="font-semibold"><h3>IP</h3></td>
                <td><h3 class="font-light pl-3">10.20.21.22</h3></td>
              </tr>
              <tr>
                <td class="font-semibold"><h3>Netmask</h3></td>
                <td><h3 class="font-light pl-3">255.0.0.0</h3></td>
              </tr>
              <tr>
                <td class="font-semibold"><h3>DG</h3></td>
                <td><h3 class="font-light pl-3">10.20.1.1</h3></td>
              </tr>
              <tr>
                <td class="font-semibold"><h3>Secret</h3></td>
                <td><h3 class="font-light pl-3">10C8070BE7</h3></td>
              </tr>
              <tr>
                <td class="font-semibold"><h3>Realm</h3></td>
                <td><h3 class="font-light pl-3">enterprisewifi</h3></td>
              </tr>
              <tr>
                <td class="font-semibold"><h3>Port</h3></td>
                <td><h3 class="font-light pl-3">1812</h3></td>
              </tr>
            </table>
          </vx-card>
        </div>
      </slide-x-left-transition>

      <slide-x-left-transition>
        <div
          v-if="wap_show_config"
          style="position: absolute; top: 22%; left: 13vw; z-index: 99; min-width: 31vw"
          id="WAPBox201394311844069376"
        >
          <vx-card class="border card-border" icon="move" title-color="white" style="overflow: auto" title="WAP Setup">
            <template slot="actions">
              <div class="flex items-center align-middle text-danger" @click="wap_show_config = false">
                <feather-icon icon="XCircleIcon" svgClasses="w-8 h-8" />
              </div>
            </template>
            <vs-tabs style="overflow: hidden">
              <vs-tab label="Basic Wireless Settings" icon-pack="feather" icon="icon-wifi">
                <div class="pt-4" style="min-height: 490px">
                  <div class="vx-row mb-6">
                    <div class="vx-col w-1/2">
                      <p class="ml-1 text-white">Wireless Network Mode</p>
                      <v-select
                        v-model="basic_wireless_settings.wireless_network_mode.selected"
                        :options="basic_wireless_settings.wireless_network_mode.values"
                        class="w-full mt-2"
                        placeholder="Select from Dropdown"
                      />
                      <small
                        v-if="basic_wireless_settings.wireless_network_mode.marked_score === false"
                        class="ml-1 text-danger font-semibold"
                        >This answer is incorrect</small
                      >
                      <small
                        v-if="basic_wireless_settings.wireless_network_mode.marked_score === true"
                        class="ml-1 text-success font-semibold"
                        >This answer is correct</small
                      >
                    </div>
                    <div class="vx-col w-1/2">
                      <p class="ml-1 text-white">Wireless Network Name (SSID)</p>
                      <vs-input v-model="basic_wireless_settings.ssid.selected" placeholder="Enter SSID" class="w-full mt-2" id="ssid" />
                      <small v-if="basic_wireless_settings.ssid.marked_score === false" class="ml-1 text-danger font-semibold"
                        >This answer is incorrect</small
                      >
                      <small v-if="basic_wireless_settings.ssid.marked_score === true" class="ml-1 text-success font-semibold"
                        >This answer is correct</small
                      >
                    </div>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col w-1/2">
                      <p class="ml-1 text-white">Wireless Channel</p>
                      <v-select
                        v-model="basic_wireless_settings.channel.selected"
                        :options="basic_wireless_settings.channel.values"
                        class="w-full mt-2"
                        placeholder="Select from Dropdown"
                      />
                      <small v-if="basic_wireless_settings.channel.marked_score === false" class="ml-1 text-danger font-semibold"
                        >This answer is incorrect</small
                      >
                      <small v-if="basic_wireless_settings.channel.marked_score === true" class="ml-1 text-success font-semibold"
                        >This answer is correct</small
                      >
                    </div>
                    <div class="vx-col w-1/2">
                      <p class="ml-1 text-white">Wireless SSID Broadcast</p>
                      <v-select
                        v-model="basic_wireless_settings.broadcast.selected"
                        :options="basic_wireless_settings.broadcast.values"
                        class="w-full mt-2"
                        placeholder="Select from Dropdown"
                      />
                      <small v-if="basic_wireless_settings.broadcast.marked_score === false" class="ml-1 text-danger font-semibold"
                        >This answer is incorrect</small
                      >
                      <small v-if="basic_wireless_settings.broadcast.marked_score === true" class="ml-1 text-success font-semibold"
                        >This answer is correct</small
                      >
                    </div>
                  </div>
                </div>
              </vs-tab>
              <vs-tab label="Wireless Security" icon-pack="feather" icon="icon-lock">
                <div class="pt-4" style="min-height: 520px">
                  <div class="vx-row mb-6">
                    <div class="vx-col w-1/2">
                      <p class="ml-1 text-white">Security Mode</p>
                      <v-select
                        v-model="wireless_security.security_mode.selected"
                        :options="wireless_security.security_mode.values"
                        class="w-full mt-2"
                        placeholder="Select from Dropdown"
                      />
                      <small v-if="wireless_security.security_mode.marked_score === false" class="ml-1 text-danger font-semibold"
                        >This answer is incorrect</small
                      >
                      <small v-if="wireless_security.security_mode.marked_score === true" class="ml-1 text-success font-semibold"
                        >This answer is correct</small
                      >
                    </div>
                    <div class="vx-col w-1/2">
                      <p class="ml-1 text-white">WPA Algorithms</p>
                      <v-select
                        v-model="wireless_security.wpa_algorithms.selected"
                        :options="wireless_security.wpa_algorithms.values"
                        class="w-full mt-2"
                        placeholder="Select from Dropdown"
                      />
                      <small v-if="wireless_security.wpa_algorithms.marked_score === false" class="ml-1 text-danger font-semibold"
                        >This answer is incorrect</small
                      >
                      <small v-if="wireless_security.wpa_algorithms.marked_score === true" class="ml-1 text-success font-semibold"
                        >This answer is correct</small
                      >
                    </div>
                  </div>
                  <div class="vx-row mb-6">
                    <div class="vx-col w-1/2">
                      <p class="ml-1 text-white">RADIUS Server Address</p>
                      <vs-input
                        v-model="wireless_security.radius_server_address.selected"
                        placeholder="Enter RADIUS Server Address"
                        class="w-full mt-2"
                      />
                      <small v-if="wireless_security.radius_server_address.marked_score === false" class="ml-1 text-danger font-semibold"
                        >This answer is incorrect</small
                      >
                      <small v-if="wireless_security.radius_server_address.marked_score === true" class="ml-1 text-success font-semibold"
                        >This answer is correct</small
                      >
                    </div>
                    <div class="vx-col w-1/2">
                      <p class="ml-1">RADIUS Port</p>
                      <vs-input
                        v-model="wireless_security.radius_port.selected"
                        placeholder="Enter RADIUS Server Port"
                        class="w-full mt-2"
                      />
                      <small v-if="wireless_security.radius_port.marked_score === false" class="ml-1 text-danger font-semibold"
                        >This answer is incorrect</small
                      >
                      <small v-if="wireless_security.radius_port.marked_score === true" class="ml-1 text-success font-semibold"
                        >This answer is correct</small
                      >
                    </div>
                  </div>
                  <div class="vx-row mb-6">
                    <div class="vx-col w-1/2">
                      <p class="ml-1 text-white">Shared Key</p>
                      <vs-input
                        v-model="wireless_security.shared_key.selected"
                        placeholder="Enter RADIUS Server Address"
                        class="w-full mt-2"
                      />
                      <small v-if="wireless_security.shared_key.marked_score === false" class="ml-1 text-danger font-semibold"
                        >This answer is incorrect</small
                      >
                      <small v-if="wireless_security.shared_key.marked_score === true" class="ml-1 text-success font-semibold"
                        >This answer is correct</small
                      >
                    </div>
                    <div class="vx-col w-1/2">
                      <p class="ml-1 text-white">Key Renewal Timeout</p>
                      <vs-input v-model="wireless_security.key_renewal_timeout.selected" class="w-full mt-2" disabled="" />
                      <small v-if="wireless_security.key_renewal_timeout.marked_score === false" class="ml-1 text-danger font-semibold"
                        >This answer is incorrect</small
                      >
                      <small v-if="wireless_security.key_renewal_timeout.marked_score === true" class="ml-1 text-success font-semibold"
                        >This answer is correct</small
                      >
                    </div>
                  </div>
                </div>
              </vs-tab>
            </vs-tabs>
          </vx-card>
        </div>
      </slide-x-left-transition>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      aaa_server_show: false,

      wap_show_config: false,

      basic_wireless_settings: {
        wireless_network_mode: {
          values: ['G ONLY', 'B ONLY', 'BG ONLY', 'N ONLY', 'MIXED', 'DISABLED'],
          correct: 'MIXED',
          selected: null,
          marked_score: null,
        },
        ssid: {
          correct: 'enterprisewifi',
          selected: null,
          marked_score: null,
        },
        channel: {
          values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
          correct: ['1', '6', '11'],
          selected: null,
          marked_score: null,
        },
        broadcast: {
          values: ['ENABLED', 'DISABLED'],
          correct: 'DISABLED',
          selected: 'ENABLED',
          marked_score: null,
        },
      },

      wireless_security: {
        security_mode: {
          values: ['OPEN', 'WEP', 'WPA', 'WPA2', 'WPA2 ENTERPRISE', 'WPA3'],
          correct: 'WPA2 ENTERPRISE',
          selected: null,
          marked_score: null,
        },
        wpa_algorithms: {
          values: ['TKIP', 'WEP', 'WPA', 'WPA2', 'AES', 'CCMP', 'EAP'],
          correct: 'AES',
          selected: null,
          marked_score: null,
        },
        radius_server_address: {
          correct: '10.20.21.22',
          selected: null,
          marked_score: null,
        },
        radius_port: {
          correct: '1812',
          selected: null,
          marked_score: null,
        },
        shared_key: {
          correct: '10C8070BE7',
          selected: null,
          marked_score: null,
        },
        key_renewal_timeout: {
          correct: '3600 Seconds',
          selected: '3600 Seconds',
          marked_score: null,
        },
      },
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      for (const [key, value] of Object.entries(this.basic_wireless_settings)) {
        if (!Array.isArray(value.correct)) {
          if (value.selected === value.correct) {
            this.basic_wireless_settings[key].marked_score = true;
            totalScore++;
          } else {
            this.basic_wireless_settings[key].marked_score = false;
          }
        } else if (value.correct.includes(value.selected)) {
          this.basic_wireless_settings[key].marked_score = true;
          totalScore++;
        } else {
          this.basic_wireless_settings[key].marked_score = false;
        }
      }

      for (const [key, value] of Object.entries(this.wireless_security)) {
        if (!Array.isArray(value.correct)) {
          if (value.selected === value.correct) {
            this.wireless_security[key].marked_score = true;
            totalScore++;
          } else {
            this.wireless_security[key].marked_score = false;
          }
        } else if (value.correct.includes(value.selected)) {
          this.wireless_security[key].marked_score = true;
          totalScore++;
        } else {
          this.wireless_security[key].marked_score = false;
        }
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Security+/Lgx201394311844069376.scss';

button.vs-tabs--btn {
  color: #ffffff;
}
</style>
